<template lang="pug">
  v-container(fluid)
    v-toolbar.mb-2(
      color="primary"
      dark
      dense
      elevation="0"
    )
      span New Order Status
    v-row
      v-col.pb-0(cols="12")
        v-text-field(
          label="ID"
          outlined
          dense
          v-model="id"
          :error-messages="orderStatusPostErrors.id || []"
        )
      v-col.py-0(cols="12")
        v-text-field(
          label="Name"
          outlined
          dense
          v-model="name"
          :error-messages="orderStatusPostErrors.name || []"
        )
      v-col.py-0(cols="12")
        v-btn(
          color="primary"
          @click="storeOrderStatus"
          :loading="orderStatusPosting"
        )
          span Save
</template>
<script>
import orderStatusRepository from '@/repositories/order-status.repository'
import searchDelay from '@/libs/searchDelay.extra'
import { postVars, errorHandler } from '@/libs/api-helper.extra'

const orderStatusPostDelay = searchDelay()

const inputVars = () => ({
  id: null,
  name: null,
})
export default {
  name: 'OrderStatusCreate',
  data () {
    return {
      ...inputVars(),
      ...postVars('order-status'),
    }
  },
  methods: {
    // ---1
    storeOrderStatus () {
      if (this.orderStatusPosting) return
      this.setOrderStatusToPosting()
      this.clearOrderStatusPostErrors()
      const inputData = this.getInputData()
      orderStatusPostDelay(this.postDataToOrderStatuses(inputData))
    },
    // ---2
    setOrderStatusToPosting () {
      this.orderStatusPosting = true
    },
    clearOrderStatusPostErrors () {
      this.orderStatusPostErrors = []
    },
    postDataToOrderStatuses (data) {
      return () => {
        orderStatusRepository.store(data)
          .then(this.setOrderStatusPostSuccess)
          .catch(this.setOrderStatusPostErrors)
          .then(this.setOrderStatusToNotPosting)
      }
    },
    getInputData () {
      const { id, name } = this
      return { id, name }
    },
    // ---3
    setOrderStatusPostSuccess () {
      this.reset(inputVars())
    },
    setOrderStatusPostErrors (errors) {
      this.orderStatusPostErrors = errorHandler(errors)
    },
    reset (defaults) {
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
    },
    setOrderStatusToNotPosting () {
      this.orderStatusPosting = false
    },
  },
}
</script>